import { Theme } from "#/types.ts/other";
import Head from "next/head";
import { createContext } from "react";

const defaultValue: Theme = {
  borderColor: "#DDE0E9",
  accentColor: "#DDE0E9",
  primaryColor: "#27C499",
  secondaryColor: "#0B1130",
  mutedColor: "#80849C",
  bgColor: "#F4F7FC",
  foregroundColor: "#0B1130",
  cardForegroundColor: "#0B1130",
  // non editable
  primaryForegroundColor: "#ffff",
  primaryMutedColor: "rgba(255, 255, 255, 0.64)",
  secondaryForegroundColor: "#ffff",
  cardColor: "white",
  destructiveColor: "#C4274D",
  destructiveForegroundColor: "#fff",
  secondaryAccentColor: "rgba(255, 255, 255, 0.1)",
  secondaryMutedColor: "rgba(255, 255, 255, 0.64)",
  secondaryBorderColor: "rgba(255, 255, 255, 0.24)",
  imageUrl:
    "https://res.cloudinary.com/hzqnlvm35/image/upload/v1634793905/stream-images/streamlogo_anu8dq.png",
  iconUrl:
    "https://res.cloudinary.com/hzqnlvm35/image/upload/v1657063674/stream-images/logo_1_p49tdn.png",
  name: "Stream",
};

export const ThemeContext = createContext<Theme>(defaultValue);

type ThemeProviderProps = {
  theme: Theme;
  children: React.ReactNode | React.ReactNode[];
};

export const ThemeProvider = ({
  theme = defaultValue,
  children,
}: ThemeProviderProps) => {
  const cssVariables = `
  :root {
    --accent-color: ${theme.accentColor};
    --bg-color: ${theme.bgColor};
    --foreground-color: ${theme.foregroundColor};   
    --muted-color: ${theme.mutedColor};   
    --card-color: ${theme.cardColor};   
    --card-foreground-color: ${theme.cardForegroundColor};       
    --border-color: ${theme.borderColor};     
    --primary-color: ${theme.primaryColor};   
    --primary-foreground-color: ${theme.primaryForegroundColor}; 
    --primary-muted-color: ${theme.primaryMutedColor};
    --secondary-color: ${theme.secondaryColor};   
    --secondary-foreground-color: ${theme.secondaryForegroundColor}; 
    --secondary-muted-color: ${theme.secondaryMutedColor};
    --secondary-accent-color: ${theme.secondaryAccentColor}; 
    --secondary-border-color: ${theme.secondaryBorderColor}; 
    --destructive-color: ${theme.destructiveColor};   
    --destructive-foreground-color: ${theme.destructiveForegroundColor};           
  
  }

  body {
    background-color: var(--bg-color);
    text: var(--foreground-color);
  }

  .menu-match-select {
    --rmsc-main: ${theme.foregroundColor} !important;
    --rmsc-hover: ${theme.bgColor} !important;
    --rmsc-selected: #fff !important;
    --rmsc-border: transparent;
    --rmsc-gray: ${theme.mutedColor} !important;
    --rmsc-bg: ${theme.cardColor} !important;
    color: ${theme.foregroundColor} !important;
    --rmsc-p: 15px !important; /* Spacing */
    --rmsc-radius: 10px !important; /* Radius */
    --rmsc-h: 80px !important; /* Height */
    min-width: 100% !important;
    max-width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    .dropdown-container .dropdown-heading {
      height: 96px !important;
    }
   }

  .menu-select {
      --rmsc-main: white !important;
      --rmsc-hover: #fff !important;
      --rmsc-selected: #fff !important;
      --rmsc-border: #fff !important;
      --rmsc-gray: black !important;
      --rmsc-bg: #fff !important;
      --rmsc-p: 10px !important; /* Spacing */
      --rmsc-radius: 4px !important; /* Radius */
      --rmsc-h: 38px !important; /* Height */
      min-width: 100% !important;
      max-width: 100% !important;
      border: 0px !important;
      background-color: white;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
      min-height: 4em !important;
      border-radius: 5px !important;
      align-items: center !important;
      display: flex !important;
      justify-content: center !important;
   }
`;

  return (
    <ThemeContext.Provider value={theme}>
      <Head>
        <style dangerouslySetInnerHTML={{ __html: cssVariables }} />
        <link rel="icon" type="image/x-icon" href={theme.iconUrl} />
      </Head>
      {children}
    </ThemeContext.Provider>
  );
};
