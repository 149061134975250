import { ErrorBoundary } from "#/components/error-boundary/error-boundary";
import FallbackError from "#/components/layout/fallback-error";
import ToastContainer from "#/components/toast-container";
import { fonts } from "#/lib/fonts";
import { queryClient } from "#/lib/query";
import { fetchRequest } from "#/lib/stream-api";
import { toast } from "#/lib/toast";
import { ThemeProvider } from "#/providers/ThemeProvider";
import "#/styles/globals.css";
import { datadogRum } from "@datadog/browser-rum";
import { QueryClientProvider } from "@tanstack/react-query";
import { appWithTranslation } from "next-i18next";
import { Suspense, useEffect, useState } from "react";
import "react-dates/initialize";
import { IntercomProvider } from "react-use-intercom";
import "react-virtualized/styles.css";
import "styles/custom-skeleton.css";
import { SWRConfig } from "swr";
import { twMerge } from "tailwind-merge";
import "../styles/nprogress.css"; //styles of nprogress

datadogRum.init({
  applicationId: "2c1b744a-5c45-4242-a72c-72cb37b1ba02",
  clientToken: "pub9ff38d8cc8aeda7b68b08e4446dfe789",
  site: "datadoghq.com",
  service: "web",
  env: `${process.env.NEXT_PUBLIC_APP}_${process.env.NEXT_PUBLIC_ENV}`,
  version: process.env.VERCEL_GIT_COMMIT_SHA || "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

function App({ Component, pageProps }: any) {
  const [client, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  if (!client) return null;

  return (
    <SWRConfig
      value={{
        fetcher: fetchRequest,
        onError: (e) => toast.error(e?.message),
      }}
    >
      <IntercomProvider appId="c8svb7mj">
        <ThemeProvider theme={pageProps?.theme}>
          <ErrorBoundary fallback={<FallbackError />}>
            <QueryClientProvider client={queryClient}>
              <ToastContainer />
              <Suspense>
                <main
                  className={twMerge(
                    fonts.map((f) => f.variable),
                    "font-circular font-400 h-[100vh] w-full",
                  )}
                >
                  <Component {...pageProps} />
                </main>
              </Suspense>
            </QueryClientProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </IntercomProvider>
    </SWRConfig>
  );
}

export default appWithTranslation(App);
