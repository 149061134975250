import { SVGIcon } from "#/ui/svg-icon";
import {
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { X } from "lucide-react";
import { toast as sonnerToaster } from "sonner";

const ToastContainer = ({
  text,
  icon,
}: {
  text: string;
  icon: React.ReactNode;
}) => (
  <div className="z-100 rounded-xl bg-secondary h-[4rem] flex items-center justify-between px-3 min-w-[15rem] max-w-[20rem]">
    <div className="flex items-center mr-3">
      {icon && icon}
      <p className="text-base font-circular-std font-600 text-secondary-foreground line-clamp-2">
        {text}
      </p>
    </div>
    <X
      onClick={() => sonnerToaster.dismiss()}
      className="h-4 cursor-pointer text-muted w-4"
    />
  </div>
);

export const toast = {
  success: (text: string) =>
    sonnerToaster.custom(() => (
      <ToastContainer
        text={text}
        icon={<SVGIcon svg="success" className="h-8 w-8 mr-3" />}
      />
    )),
  error: (text: string) =>
    sonnerToaster.custom(() => (
      <ToastContainer
        text={text}
        icon={<XCircleIcon className="h-9 text-red-500 w-9 mr-2" />}
      />
    )),
  warn: (text: string) =>
    sonnerToaster.custom(() => (
      <ToastContainer
        text={text}
        icon={
          <ExclamationTriangleIcon className="h-9 w-9 text-yellow-500 mr-2" />
        }
      />
    )),
};
