import { useTranslation } from "next-i18next";
import f from "../public/locales/en/common.json";

type NestedKeys<T, Prefix extends string = ""> = {
  [K in keyof T & string]: T[K] extends object
    ? NestedKeys<T[K], `${Prefix}${K}.`>
    : `${Prefix}${K}`;
}[keyof T & string];

type CommonKeys = NestedKeys<typeof f>;

type ObjectPaths<T, Prefix extends string = ""> = {
  [K in keyof T & string]: T[K] extends object
    ? `${Prefix}${K}` | ObjectPaths<T[K], `${Prefix}${K}.`>
    : never;
}[keyof T & string];

type ValidPrefixes = ObjectPaths<typeof f>;

type Split<S extends string, D extends string> = string extends S
  ? string[]
  : S extends `${infer T}${D}${infer Rest}`
  ? [T, ...Split<Rest, D>]
  : [S];

type GetSubKeys<T, P extends string[]> = P extends [infer First, ...infer Rest]
  ? First extends keyof T
    ? GetSubKeys<T[First & keyof T], Rest extends string[] ? Rest : []>
    : never
  : keyof T;

export function useTStream(): {
  t: (key: CommonKeys) => string;
};

export function useTStream<P extends ValidPrefixes>(
  prefix: P,
): {
  t: (key: GetSubKeys<typeof f, Split<P, ".">>, body?: any) => string;
};

export function useTStream(prefix?: ValidPrefixes) {
  const { t } = useTranslation("common");

  return {
    t: (key: string, body?: any) => {
      const fullKey = `${prefix ? `${prefix}.` : ""}${key}`;
      return t(fullKey, body || {});
    },
  };
}
