import { svgs } from "#/ui/svgs";

type SVGIconProps = {
  className: string;
  onClick?: () => void;
  svg:
    | "percentage"
    | "dollar"
    | "chevronDouble"
    | "type"
    | "menu"
    | "chevronWide"
    | "note"
    | "circle"
    | "arrowControls"
    | "list"
    | "mm-connect"
    | "grabber"
    | "checkList"
    | "chevronThickDown"
    | "grid"
    | "success"
    | "error"
    | "staggered";
};

export const SVGIcon = ({ className, svg, onClick }: SVGIconProps) => {
  return (
    <div className={className} onClick={onClick}>
      {svgs[svg]}
    </div>
  );
};
