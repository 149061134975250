import localFont from "next/font/local";

const circular = localFont({
  variable: "--font-circular",
  src: [
    {
      path: "../public/fonts/circular-400.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../public/fonts/circular-500.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../public/fonts/circular-700.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../public/fonts/circular-700.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../public/fonts/circular-800.ttf",
      weight: "800",
      style: "normal",
    },
  ],
});

const circularStd = localFont({
  variable: "--font-circular-std",
  src: [
    {
      path: "../public/fonts/circular-std-400.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../public/fonts/circular-std-500.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../public/fonts/circular-std-700.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../public/fonts/circular-std-700.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../public/fonts/circular-std-800.ttf",
      weight: "800",
      style: "normal",
    },
  ],
});

export const fonts = [circular, circularStd];
