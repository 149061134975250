import { Toaster } from "#/ui/sonner";

export default function ToastContainer() {
  return (
    <Toaster
      toastOptions={{
        style: {
          background: "transparent",
        },
        className: "class",
      }}
    />
  );
}
